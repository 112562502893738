import april from "../Files/officer_pics/officers_2023-2024/april.jpg"
import ben from "../Files/officer_pics/officers_2023-2024/ben.jpeg"
import brandon from "../Files/officer_pics/officers_2023-2024/brandon.jpg"
import victor from "../Files/officer_pics/officers_2023-2024/victor.jpeg"
import vivian from "../Files/officer_pics/officers_2023-2024/vivian.jpeg"
import keshav from "../Files/officer_pics/officers_2023-2024/keshav.jpg"
import dustin from "../Files/officer_pics/officers_2023-2024/dustin.jpg"
import gavin from "../Files/officer_pics/officers_2023-2024/gavin.jpeg"
import sophia from "../Files/officer_pics/officers_2023-2024/sophia.jpg"
import chloe from "../Files/officer_pics/officers_2023-2024/chloe.jpeg"
import prad from "../Files/officer_pics/officers_2023-2024/prad.jpeg"
import granger from "../Files/officer_pics/officers_2023-2024/granger.jpg"
import hannah from "../Files/officer_pics/officers_2023-2024/hannah.jpeg"
import ashton from "../Files/officer_pics/officers_2023-2024/ashton.jpg"
import josh from "../Files/officer_pics/officers_2023-2024/josh.jpg"
//template for buffer spots
// {
//     "image": "",
//     "name": "",
//     "title": "",
//     "college": "",
//     "major": ""3
// },

export default [
  //multi-line strings should be encapuslated with the ` character, same key as the ~ (tilde)
  //hometown is major. we should change that
  {
    image: victor,
    name: "Victor Xie",
    title: "Co-President",
    college: "Lovett 2025",
    major: "COMP, DSCI",
  },
  {
    image: vivian,
    name: "Vivian Xiao",
    title: "Co-President",
    college: "Sid 2025",
    major: "COMP, VADA",
  },
  {
    image: april,
    name: "April Tong",
    title: "Co-External VP",
    college: "Lovett 2026",
    major: "COMP",
  },
  {
    image: hannah,
    name: "Hannah Zhang",
    title: "Co-External VP",
    college: "Lovett 2026",
    major: "COMP, BUSI, ASIA",
  },
  {
    image: keshav,
    name: "Keshav Shah",
    title: "Co-Internal VP",
    college: "Baker 2026",
    major: "COMP",
  },
  {
    image: dustin,
    name: "Dustin Ho",
    title: "Co-Internal VP",
    college: "Baker 2025",
    major: "COMP",
  },
  {
    image: ashton,
    name: "Ashton Lee",
    title: "Co-Internal VP",
    college: "Martel 2026",
    major: "COMP, MATH",
  },
  {
    image: josh,
    name: "Josh Stallings",
    title: "Treasurer",
    college: "Duncan 2026",
    major: "COMP, NEURO, DSCI",
  },
  {
    image: chloe,
    name: "Chloe Lim",
    title: "Publicist",
    college: "Martel 2026",
    major: "COMP, DSCI",
  },
  {
    image: sophia,
    name: "Sophia Xing",
    title: "Co-Events Chair",
    college: "Hanszen 2027",
    major: "COMP, MATH, BUSI",
  },
  {
    image: gavin,
    name: "Gavin Ni",
    title: "Co-Events Chair",
    college: "Brown 2027",
    major: "COMP, BUSI",
  },
  {
    image: granger,
    name: "Granger Bering-James",
    title: "Co-Freshmen Rep",
    college: "Baker 2028",
    major: "COMP, BUSI",
  },
  {
    image: prad,
    name: "Prad Chebolu ",
    title: "Co-Freshmen Rep",
    college: "Duncan 2028",
    major: "COMP, COG SCI",
  }
];
